const dev = {
  API_ENDPOINT_URL: "/api",
};

const prod = {
  API_ENDPOINT_URL: "/api",
};

const test = {
  API_ENDPOINT_URL: "/api",
};

// export const BASE_ENDPOINT_URL = `http://34.249.240.95:5008/api`;
// export const BASE_ENDPOINT_URL = `https://b2blicencetest.vestek.com.tr/api`; //`https://cloudlicencemanager.com/B2B/api`;
export const BASE_ENDPOINT_URL = `https://cloudlicencemanager.com/api`;

export const COMPANY_URL = `${BASE_ENDPOINT_URL}/company`;
export const LICENCE_URL = `${BASE_ENDPOINT_URL}/licence`;
export const PROJECT_URL = `${BASE_ENDPOINT_URL}/project`;
export const USER_URL = `${BASE_ENDPOINT_URL}/user`;
export const USER_URL_PROFILE = `${USER_URL}/EditUserProfile`;
export const DASHBOARD_URL = `${BASE_ENDPOINT_URL}/dashboard`;

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
